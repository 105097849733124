import React from "react"

export default function DialogodisplayFU(params) {
    
    return (
        <div className='rounded-lg'>
            <div className=''>
                <div id='box-mensaje'
                    className={params.index % 2 === 0 ?
                        'pt-1 font-mono px-1 bg-yellow-200 text-black rounded-lg '
                        :
                        'pt-1 font-mono bg-orange-300 rounded-lg text-black'}>
                    <div id='dialogo' className=" rounded-lg" >
                        <div className="px-1 font-semibold"> {'Fecha: '}
                            {new Date(params.FECDIALOGO).toLocaleDateString()}
                            {" "}
                            {new Date(params.FECDIALOGO).toLocaleTimeString()}
                        </div>
                        <div className='px-1 '>
                            {"De "}{params.REMITENTE}{"  para : "}{params.DESTINATARIO}
                            <div>{params.TXTDIALOGO}</div>

                        </div>
                    </div>
                    <div id="adjuntos" className="pb-2 px-1 ">
                        {params.nomfoto !== undefined ?
                            <div id='imagen-adjunta' className='px-1 pt-1'>
                                <img className='mx-auto top-0 rounded-lg max-w-full '
                                    src={'https://escuela.caminantes.cl/images/dialogs/' + params.nomfoto}
                                    alt={params.nomfoto} />
                            </div>
                            :
                            <></>
                        }
                        <div id='documento'
                            className=' text-md text-blue-800 pb-2 pt-1 text-nowrap overflow-hidden'>
                            {params.nomdoc === undefined ?
                                <></>
                                :
                                <div className="flex pt-1 pb-1  w-full text-center lg:w-fit">
                                    <a rel='noreferrer'
                                        className=' py-1 bg-violet-500 text-white w-full font-semibold rounded-lg h-8 px-1'
                                        target='_blank' download={true}
                                        href={'https://escuela.caminantes.cl/docs/' + params.nomdoc}>
                                        {'Doc: '}{params.nomdoc}

                                    </a>
                                </div>
                            }
                        </div>
                        <div className="lg:w-fit text-nowrap overflow-hidden">
                            {params.link !== '' ?
                                <div className=" w-full px-1 font-bold text-white py-1 mx-auto sm:w-full text-center bg-violet-500 rounded-lg">
                                    <div className=" text-nowrap">
                                        <a
                                            className="mx-auto text-white"
                                            href={params.link}>{params.link}
                                        </a>
                                    </div>
                                </div>
                                : <></>}
                        </div>




                    </div>
                </div>
            </div>
        </div>
    )
}
