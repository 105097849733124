import React, { useState, useEffect } from 'react'
import axios from 'axios';
import useSessionStorageState from 'use-session-storage-state'
import Swal from 'sweetalert2'

const Signin = (params) => {
    // eslint-disable-next-line no-unused-vars
    const [admin, setAdmin] = useSessionStorageState('admin')
    // eslint-disable-next-line no-unused-vars
    const [logged, setLogged] = useSessionStorageState('logged');
    // eslint-disable-next-line no-unused-vars
    const [usuario, setUsuario] = useSessionStorageState('usuario');
    const [grupo, setGrupo] = useSessionStorageState('grupo');
    // eslint-disable-next-line no-unused-vars
    const [ok, setOk] = useState(true);
    //eslint-disable-next-line no-unused-vars
    const [inputs, setInputs] = useState([]);
    const handleChanges = (event) => {
        event.preventDefault();
        setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
    }
    const validaCampos = () => {
        console.log(admin, usuario, logged)
        var elem = document.getElementsByTagName('input');
        var errors = 0;
        for (let i = 0; i < elem.length; i++) {
            if (elem[i].value === "" || elem[i].value === " ") {
                ++errors;
                elem[i].className = " bg-orange-100 values relative block w-full appearance-none rounded-none rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            };
        }
        if (errors !== 0) {
            return false;
        } else
            return true;
    }
    // eslint-disable-next-line no-extend-native
    String.prototype.shuffle = function () {
        var a = this.split(''),
            n = a.length;
        for (var i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join('');
    }
    // const cambiarPass = (rut) => {
    //     if (rut !== '') {
    //         const postNewpass = async () => {
    //             let newPass = rut.shuffle();
    //             // setPagina('cambia');
    //             document.getElementById('nombreusuario').innerText = 'Copia tu nueva clave'
    //             let aviso = document.getElementById('nuevaclave')
    //             aviso.className = 'mx-auto bg-black text-white h-10 w-1/8 py-1 font-bold text-center'
    //             aviso.innerText = newPass
    //             aviso.onclick = () => {
    //                 navigator.clipboard.writeText(newPass);
    //                 console.log("Nueva Password copiada: " + newPass);
    //                 aviso.innerText = ''
    //                 aviso.className = ''
    //                 document.getElementById('msgError').innerText = 'Ingresa con tu RUT y la nueva clave Caminantes '
    //             }
    //             try {
    //                 await axios.post("https://familia.caminantes.cl/modpass", {
    //                     method: "POST",
    //                     mode: "cors",  // Change the mode to CORS
    //                     params: {
    //                         rutusuario: rut,
    //                         newpass: newPass
    //                     },
    //                 });
    //             } catch (error) {
    //                 console.error(error);
    //             }
    //         }
    //         postNewpass()
    //     }
    // }
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const validaPass = (event) => {
        event.preventDefault();
        if (validaCampos) {
            let inputuser = inputs.username
            let inputpass = inputs.password
            const fetchData = async () => {
                try {
                    const response = await axios.post("https://familia.caminantes.cl/user/validation/", {
                        method: "GET",
                        mode: "cors",  // Change the mode to CORS
                        params: {
                            rutusuario: inputuser,
                            password: inputpass
                        }}
                    );
                    if (response.status === 200) {
                        if (response.data.data.length === 1) {
                            document.getElementById('msgError').innerText = ""
                            setLogged(true);
                            document.getElementById('msgError').innerText = ""
                            setLogged(true);
                            setUsuario(response.data.data[0])
                            setAdmin(response.data.data[0].TIPUSUARIO === 2 ? true : false);
                            setGrupo(response.data.data[0].GRUPO)
                            Toast.fire({
                                icon: "success",
                                title: "Te damos la bienvenida " + response.data.data[0].NOMUSUARIO
                            })

                            await fetch("https://php.caminantes.cl/loglogin.php?nombre='"
                                + response.data.data[0].NOMUSUARIO + ' ' + grupo + ' '
                                + inputs.username + ' ' + new Date().toLocaleString() + "'", {
                                method: 'POST',
                                mode: 'no-cors'
                            });
                            
                        } else {
                            document.getElementById('msgError').innerText = "Vuelve a intentar, Revisa tu Rut y Password"
                            setOk(!ok)
                        }
                    }
                    else {
                        setLogged(false);
                        setInputs([])
                        inputpass.value = '';
                        inputuser.value = '';
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
            document.getElementById('username').value = ''
            document.getElementById('password').value = ''
        }
    }
    useEffect(() => {
        setInputs([])
        document.getElementById('username').value = ''
        document.getElementById('password').value = ''
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div id='cajalogin' className="lg:w-1/4 mx-auto bg-violet-400 rounded-lg"  >
                <div>
                    <h2
                        className=" mt-4 pt-1 text-center text-2xl font-bold tracking-tight text-gray-900">
                        Caminar Conectados
                    </h2>
                </div>
                <form className="mt-1 space-y-6 px-8 rounded-lg" method="GET" >
                    <div className="rounded-lg">
                        <div id='user' className='pb-1 rounded-lg'>
                            <label htmlFor="username" className="sr-only">
                                Rut
                            </label>
                            <input
                                onChange={handleChanges}
                                id="username"
                                name="username"
                                type="text"
                                autoComplete="true"
                                required
                                className=" relative block w-full appearance-none rounded-lg rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Tu Rut"
                            />
                        </div>
                        <div id='pass' className='pb-1 rounded-lg' >
                            <label htmlFor="password" className="sr-only">
                                Tu clave Caminantes
                            </label>
                            <input
                                onChange={handleChanges}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="true"
                                required
                                className="block 
                                    w-full appearance-none rounded-lg rounded-b-md 
                                    border border-grey500 px-3 py-2 text-gray-900 
                                    placeholder-gray-500 focus:z-10 focus:border-indigo-500 
                                    focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="Tu clave caminantes"
                            />
                        </div>
                        <div className='pb-8'>
                            <button
                                onClick={validaPass}
                                type="submit"
                                className="group relative flex 
                                                            w-full justify-center rounded-md border 
                                                            border-transparent bg-slate-600 py-2 px-4
                                                            text-sm font-medium text-white hover:bg-indigo-700 
                                                            focus:outline-none focus:ring-2 focus:ring-indigo-500 
                                                            focus:ring-offset-2">

                                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                                Ingresar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>

    )
}

export default Signin;
